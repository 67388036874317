<template lang="pug">
    .cta-view
        v-spacer
        v-btn(tile :href="listing.permalink" target="_blank" rel="noopener" :small="$vuetify.breakpoint.smAndDown" depressed color="accent" block).black--text
            v-icon.mr-1 mdi-eye
            | Go to listing
        //- v-btn(tile :href="previewLink" target="_blank" rel="noopener" :small="$vuetify.breakpoint.smAndDown" depressed color="accent" block).black--text
        //-     v-icon.mr-1 mdi-eye
        //-     | Preview listing
        //- v-btn(tile :href="listing.permalink" target="_blank" rel="noopener" small plain color="secondary" block).black--text.mt-3
        //-     v-icon.mr-1 mdi-link
        //-     | Agency's listing
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "CardCtaView",
  props: { listing: { type: Object, required: true } },
  computed: {
    ...get({ hostname: "hostname" }),
    previewLink() {
      return (
        this.hostname +
        "/cleaner?url=" +
        encodeURIComponent(this.listing.permalink)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.cta-view {
  width: 100%;
  //   position: absolute;
  bottom: 0;
  left: 0;
}
</style>
